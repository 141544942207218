import React, { useEffect, useState } from "react";
import { useAuth } from "../Login/AuthContext.js";
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Button,
  ListItem,
  Collapse,
} from "@mui/material";

const AccountController = () => {
  const { userData } = useAuth();

  const [selectedDriver, setSelectedDriver] = useState([]);

  const [material, setMaterial] = useState([]);

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);

  const handleDriverData = (driver) => {
    setSelectedDriver(driver);
  };

  const accountMenu = ["General", "Tabs"];

  const sections = ["Principal", "Depozit", "Santier"];

  const [user, setUser] = useState({});

  const [expandedItem, setExpandedItem] = useState(null);

  // Handler to toggle the expanded state of an item

  const [itemsToRemove, setItemsToRemove] = useState([]);

  const [selectedList, setSelectedList] = useState({});

  const handleItemClick = (field) => {
    setExpandedItem(expandedItem === field ? null : field);

    switch (field) {
      case "Principal":
        setSelectedList(material[0]);
        break;
      case "Depozit":
        setSelectedList(depozits[0]?.materialeDepozit[0]);
        break;
      case "Santier":
        setSelectedList(santiers[0]?.materialeSantier[0]);
        break;
    }
  };

  console.log("brrr", depozits[0]);

  useEffect(() => {
    fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/users/${userData._id}`
    )
      .then((response) => response.json())
      .then((data) => setUser(data));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [santiersResponse, depozitsResponse, materialResponse] =
          await Promise.all([
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/santier"
            ).then((response) => response.json()),
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/depozit"
            ).then((response) => response.json()),
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material",
              {
                credentials: "include",
              }
            ).then((response) =>
              response.ok ? response.json() : Promise.reject(response)
            ),
          ]);

        setSantiers(santiersResponse);
        setDepozits(depozitsResponse);

        setMaterial(materialResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleItemClick2 = (item) => {
    // Toggle items in or out of the itemsToRemove array
    if (itemsToRemove.includes(item)) {
      setItemsToRemove(itemsToRemove.filter((i) => i !== item));
    } else {
      setItemsToRemove([...itemsToRemove, item]);
    }
  };

  // Function to modify a copy of selectedList by removing items
  const handleRemoveItemsAndSend = async () => {
    // Assuming selectedList is an object and itemsToRemove are keys of this object
    let modifiedSelectedList = { ...selectedList };
    itemsToRemove.forEach((item) => {
      delete modifiedSelectedList[item];
    });

    // Send the modified copy with a PUT request
    const response = await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/users/${userData._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ configs: modifiedSelectedList }),
      }
    );

    if (response.ok) {
      console.log("Update successful");
      // Optionally reset itemsToRemove if needed
      setItemsToRemove([]);
    } else {
      console.error("Update failed");
    }
  };

  return (
    <div>
      <h3>Account Configs</h3>

      <div>
        <div style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
          <div>
            <List>
              {accountMenu?.map((each, index) => (
                <Paper
                  key={each?.index}
                  elevation={3}
                  style={{ marginBottom: 8 }}
                >
                  <ListItemButton
                    onClick={() => handleDriverData(accountMenu[index])}
                    button
                  >
                    <ListItemText primary={each} />
                  </ListItemButton>
                </Paper>
              ))}
            </List>
          </div>
          <div style={{ padding: "10px" }}>
            {selectedDriver === "General" && (
              <Paper elevation={3} style={{ padding: 8 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 12,
                  }}
                >
                  <span> Nume: {user?.name}</span>
                  <br />
                  <span>Email: {user?.email}</span>
                  <br />
                  <span>Role: {user?.role}</span>
                </div>
              </Paper>
            )}

            {selectedDriver === "Tabs" && (
              <Paper elevation={3} style={{ padding: 8 }}>
                <List>
                  {sections.map((section, index) => (
                    <React.Fragment key={index}>
                      <ListItem button onClick={() => handleItemClick(section)}>
                        <ListItemText primary={section} />
                      </ListItem>
                      <Collapse
                        in={expandedItem === section}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: 12,
                            }}
                          >
                            <List>
                              {Object.keys(selectedList).map((item, index) => (
                                <ListItemButton
                                  key={index}
                                  onClick={() => handleItemClick(item)}
                                >
                                  <ListItemText primary={item} />
                                  {itemsToRemove.includes(item) && (
                                    <span>Selected for removal</span>
                                  )}
                                </ListItemButton>
                              ))}
                            </List>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: 12,
                            }}
                          >
                            <Button
                              onClick={handleRemoveItemsAndSend}
                              variant="contained"
                              color="primary"
                            >
                              Remove Selected Items
                            </Button>

                            {/* Optionally display itemsToRemove array */}
                            <div>
                              <h4>Items to Remove:</h4>
                              <List>
                                {itemsToRemove.map((item, index) => (
                                  <ListItem key={index}>
                                    <ListItemText primary={item} />
                                    <Button
                                      onClick={() => handleItemClick(item)}
                                    >
                                      Remove
                                    </Button>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountController;
