import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Collapse } from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../utils/rapoarte/raportInventarCentralPDF";
import { generateExcelFile } from "./rapoarte/raportXLSX";
import "./listaGestiune.css";
import { useAuth } from "../../common/Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import { useDepozitContext } from "./context/AuthContext";
import InteractiveSVG from "./itemsMap/svgInteractive";
import mapSvg from "../../../images/map1.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useNavigate } from "react-router-dom";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { epochFormatDate } from "../../../utils/formats/epochFormat";
import AdaugaMaterialModal from "./modals/AdaugaMaterialModal";
import MutareMaterialModal from "./modals/MutareMaterialModal";
import EditMaterialModal from "./modals/EditeazaMaterialModal";
import DeleteMaterialModal from "./modals/DeleteMaterialModal";

import { useSnackbar } from "notistack";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NouaLista = () => {
  const { userData } = useAuth();
  const { isNewDepozit, setIsNewDepozit } = useDepozitContext();

  const navigate = useNavigate();

  const [openMaterial, setOpenMaterial] = useState(false);
  const [material, setMaterial] = useState([]);
  const [openMutare, setOpenMutare] = useState(false);
  const [materialId, setMaterialId] = useState();

  const [nume, setNume] = useState();
  const [numeFirma, setNumeFirma] = useState();
  const [model, setModel] = useState();
  const [numarInventar, setNumarInventar] = useState();

  const [categorieProdus, setCategorieProdus] = useState();
  const [dataExpirare, setDataExpirare] = useState();
  const [stoc, setStoc] = useState();

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);
  const [trigger, setTrigger] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const [desiredMaterial, setDesiredMaterial] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const uniqueCategories = [...new Set(material.map((item) => item.categorie))];

  const filterItem = (curcat, field) => {
    const newItem = material.filter((newVal) => {
      return newVal[field] === curcat;
    });

    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handleClose = () => {
    setOpenMaterial(false);
    setOpenMutare(false);
    setNume("");
    setNumeFirma("");
    setModel("");
    setStoc("");
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
    setQuantity("");
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    setSelectedButton(newSelectedButton);
  };

  const handleInputStoc = (e) => {
    const rawValue = e.target.value.replace(/\./g, "");
    setStoc(rawValue);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus();
  };

  const errorHandler = () => {
    return (
      quantity >
      selectedMaterial.stoc -
        selectedMaterial.cantitateDepozit -
        selectedMaterial.cantitateSantier
    );
  };

  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  const createMaterial = async () => {
    const epochTime = Date.parse(dataExpirare);
    const supplierData = {
      nume,
      numeFirma,
      model,
      numarInventar,
      categorie: newCategory !== "" ? newCategory : categorieProdus,
      dataExpirare: epochTime,
      stoc,
      author: userData?.name,
    };

    console.log("sup", supplierData);

    try {
      const response = await fetch(
        `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Supplier created successfully");
        enqueueSnackbar(`${nume} a fost adaugat.`, {
          variant: "success",
        });
        setOpenMaterial(!openMaterial);
        setTrigger(supplierData);
      } else {
        console.error("Failed to create supplier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialDepozit = async () => {
    const supplierData = {
      materialId: materialId,
      quantity,
      depozitId: selectedDepozit,
    };

    try {
      const response = await fetch(
        `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/move-to-depozit`,
        {
          credentials: "include",

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        enqueueSnackbar(`Materialul a fost mutat cu success.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);

    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  const moveMaterialSantier = async () => {
    const supplierData = {
      materialId: materialId,
      quantity,
      santierId: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/move-to-santier`,
        {
          credentials: "include",

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        enqueueSnackbar(`Materialul a fost mutat cu success.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [santiersResponse, depozitsResponse, materialResponse] =
          await Promise.all([
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/santier",
              {
                credentials: "include",
              }
            ).then((response) => {
              if (response.status === 401 || response.status === 403) {
                throw new Error("Session expired or unauthorized access.");
              }
              if (!response.ok) throw new Error("Failed to fetch santiers");
              return response.json();
            }),
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/depozit",
              {
                credentials: "include",
              }
            ).then((response) => {
              if (response.status === 401 || response.status === 403) {
                throw new Error("Session expired or unauthorized access.");
              }
              if (!response.ok) throw new Error("Failed to fetch depozits");
              return response.json();
            }),
            fetch(
              "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material",
              {
                credentials: "include",
              }
            ).then((response) => {
              if (response.status === 401 || response.status === 403) {
                throw new Error("Session expired or unauthorized access.");
              }
              if (!response.ok) throw new Error("Failed to fetch materials");
              return response.json();
            }),
          ]);

        setSantiers(santiersResponse);
        setDepozits(depozitsResponse);
        setMaterial(materialResponse.data); // Assuming the response has a data property
        setFilteredMaterials(materialResponse.data); // Assuming the response has a data property
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.message === "Session expired or unauthorized access.") {
          alert(error.message);
          navigate("/login");
        }
      }
    };

    fetchData();
  }, [trigger, openMaterial, openMutare]); // Add navigate to dependencies if it's not causing re-renders

  useEffect(() => {
    setMaterialId(selectedMaterial._id);
  }, [selectedMaterial]);

  //---------------Edit feature-------------

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  async function updateMaterial(e) {
    e.preventDefault();

    const editedItem = {
      nume: formEdit.nume,
      numeFirma: formEdit.numeFirma,
      model: formEdit.model,
      numarInventar: formEdit.numarInventar,
      categorie: newCategory !== "" ? newCategory : formEdit.categorie,
      // dataExpirare: formEdit.dataExpirare,
      stoc: formEdit.stoc,
      author: userData?.name,
    };

    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/${formEdit._id}`,
      {
        credentials: "include",

        method: "PUT",
        body: JSON.stringify(editedItem),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    enqueueSnackbar(`${formEdit.nume} a fost actualizat.`, {
      variant: "success",
    });
    setTrigger(formEdit?.nume);
  }

  const handleCloseEdit = (e) => {
    updateMaterial(e);
    setOpenEdit(false);
  };

  const [openDelete, setOpenDelete] = useState();

  async function deleteMaterial() {
    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/${formEdit._id}`,
      {
        credentials: "include",

        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formEdit),
      }
    );

    enqueueSnackbar(`${formEdit.nume} a fost eliminat.`, { variant: "error" });
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenDelete(false);
    setOpenEdit(false);
    setTrigger(formEdit?.nume);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setDesiredMaterial(item); // Set current item here
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const hanldeMutareDesired = (item) => {
    setMaterialId(item);
    setDesiredMaterial(item);
    setOpenMutare(true);
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>Depozit</h3>
            <span>Materiale: {material.length}</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMaterial(true)}
            >
              Adauga Material
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMutare(!openMutare)}
            >
              Muta Materiale
            </Button>

            {/* <TextField
              type="text"
              placeholder="Cauta dupa nume..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="desktop-grid-agro">
        <div className="desktop-agro">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
          </div>

          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setFilteredMaterials(material)}
            >
              Toate
            </Button>
          </div>

          {uniqueCategories.map((category) => (
            <div style={{ marginTop: "8px" }}>
              <Button
                key={category}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
                onClick={() => filterItem(category, "categorie")}
              >
                {category}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <div className="mobile-agro">
            <Button
              variant="contained"
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
            >
              Categorii
            </Button>
            <Collapse in={openCategoryMobile}>
              <div
                style={{
                  borderRight: "1px solid #ccc",
                  padding: "12px",
                  borderRadius: "4px",
                  marginRight: "4px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "grey.dark",
                      color: "white", // normal background color
                      "&:hover": {
                        bgcolor: "grey.light",
                        color: "black", // hover background color
                      },
                      width: "20%",
                    }}
                    onClick={() => handleAllCategory(material)}
                  >
                    Toate
                  </Button>
                </div>

                {uniqueCategories.map((category) => (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      key={category}
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: "grey.dark",
                        color: "white", // normal background color
                        "&:hover": {
                          bgcolor: "grey.light",
                          color: "black", // hover background color
                        },
                        width: "20%",
                      }}
                      onClick={() => filterItem(category, "categorie")}
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          <div style={{ maxHeight: "62vh", overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    Nume
                  </th>
                  <th style={{ paddingRight: "40px" }}>Producator</th>
                  <th style={{ paddingRight: "40px" }}>Model</th>
                  <th style={{ paddingRight: "40px" }}>Numar inventar</th>
                  <th style={{ paddingRight: "40px" }}>Categorie</th>
                  <th style={{ paddingRight: "40px" }}>Expira</th>
                  <th style={{ paddingRight: "40px" }}>Cantitate Depozite</th>
                  <th style={{ paddingRight: "40px" }}>Cantitate Plantatii</th>
                  <th style={{ paddingRight: "40px" }}>Stoc Depozit</th>
                  <th style={{ paddingRight: "40px" }}>Actiuni</th>
                </tr>
              </thead>
              <tbody>
                {filteredMaterials.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                      <Tooltip
                        title={
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            adaugat de {item.author} la{" "}
                            {formatCustomDateString(item.createdAt)}
                          </Typography>
                        }
                        placement="right"
                      >
                        <span>{item.nume}</span>
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.numeFirma}</td>
                    <td style={{ paddingRight: "40px" }}>{item.model}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {item.numarInventar}
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.categorie}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {epochFormatDate(item?.dataExpirare)}
                    </td>

                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.cantitateDepozit)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.cantitateSantier)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.stoc)}{" "}
                      <Tooltip
                        title={
                          <span style={{ fontSize: 16 }}>
                            {formatNumberWithDots(
                              Math.max(
                                0,
                                item.stoc -
                                  item.cantitateDepozit -
                                  item.cantitateSantier
                              )
                            )}{" "}
                            nefolosite
                          </span>
                        }
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {/* <Button
                        variant="contained"
                        sx={{
                          bgcolor: "blue.dark", // normal background color
                          "&:hover": {
                            bgcolor: "blue.light", // hover background color
                          },
                        }}
                        onClick={() => handleEditForm(item)}
                      >
                        Edit
                      </Button> */}

                      <div>
                        <Button
                          id="composition-button"
                          aria-haspopup="true"
                          sx={{ color: "black" }}
                          onClick={(event) => handleClick(event, item)} // Pass item her
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid black",
                              borderRadius: "12px",
                              backgroundColor: "white",
                              marginRight: "14px",
                            }}
                          >
                            <KeyboardArrowDownIcon />
                          </div>
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          PaperProps={{
                            elevation: 0, // Removes the shadow
                            sx: {
                              overflow: "visible",
                              filter: "none", // Removes any filter effects like shadows or blurring
                              // Controls the margin to adjust the position as needed
                              "& .MuiAvatar-root": {
                                width: 42,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              borderRadius: "4px",
                              width: 100,
                              border: "1px solid #D9DCE1",
                            },
                          }}
                        >
                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              handleEditForm(desiredMaterial);
                              handleCloseMenu();
                            }}
                          >
                            Edit
                          </MenuItem>

                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              hanldeMutareDesired(desiredMaterial);
                              handleCloseMenu();
                            }}
                          >
                            Muta
                          </MenuItem>
                          {/* <MenuItem
                            key={index + 2}
                            onClick={() => {
                              // setOpenEliminare(true);
                              handleCloseMenu();
                            }}
                          >
                            Elimina
                          </MenuItem> */}
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PDFDownloadLink
          document={
            <Factura
              materiale={material}
              section={"inventar"}
              date={currentDate}
            />
          }
          fileName={`${"Raport "}${"inventar"}${" "}${currentDate}${".pdf"}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Generating PDF..."
            ) : (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "blue.focus", // normal background color
                  "&:hover": {
                    bgcolor: "blue.focusHover", // hover background color
                  },
                }}
              >
                {" "}
                Genereaza Raport Inventar
              </Button>
            )
          }
        </PDFDownloadLink>{" "}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "8px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "green.light", // normal background color
            "&:hover": {
              bgcolor: "green.dark", // hover background color
            },
          }}
          onClick={() => generateExcelFile(material, currentDate)}
        >
          Genereaza Raport XLSX
        </Button>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <InteractiveSVG imageUrl={mapSvg} />
      </div> */}

      <AdaugaMaterialModal
        {...{
          openMaterial,
          handleModalMaterialClose,
          setNume,
          setNumeFirma,
          setModel,
          setNumarInventar,
          updateForm,
          formEdit,
          uniqueCategories,
          setNewCategory,
          newCategory,
          dataExpirare,
          setDataExpirare,
          stoc,
          handleInputStoc,
          createMaterial,
          styleInv,
        }}
      />

      <MutareMaterialModal
        {...{
          openMutare,
          handleClose,
          selectedButton,
          handleButtonChange,
          selectedMaterial,
          handleMaterialChange,
          material,
          quantity,
          handleQuantityChange,
          errorHandler,
          selectedSantier,
          handleSantierChange,
          santiers,
          selectedDepozit,
          handleDepozitChange,
          depozits,
          moveMaterialSantier,
          moveMaterialDepozit,
          styleInv,
        }}
      />

      <EditMaterialModal
        {...{
          openEdit,
          setOpenEdit,
          formEdit,
          updateForm,
          uniqueCategories,
          setNewCategory,
          newCategory,
          handleCloseEdit,
          setOpenDelete,
          userData,
          formatCustomDateString, // Assuming this is a function passed as a prop
          styleInv,
        }}
      />

      <DeleteMaterialModal
        {...{
          openDelete,
          setOpenDelete,
          formEdit,
          handleDeleteMaterial,
          styleInv,
        }}
      />

      <Modal
        open={openMutare}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div key={selectedButton}>
            <h2>{`Muta: ${desiredMaterial?.nume}`}</h2>
            <br />
            <ToggleButtonGroup
              value={selectedButton}
              exclusive
              onChange={handleButtonChange}
            >
              <ToggleButton value="depozit">Depozit</ToggleButton>
              <ToggleButton value="santier">Plantatie</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            {selectedButton === "santier" ? (
              <>
                {" "}
                <div style={{ width: "100%", gap: "5px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedSantier}
                    onChange={handleSantierChange}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {santiers.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => moveMaterialSantier()}
                    variant="contained"
                  >
                    Muta in Plantatie
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedDepozit}
                    onChange={(e) => handleDepozitChange(e)}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {depozits.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => moveMaterialDepozit()}
                    variant="contained"
                  >
                    Muta in Depozit
                  </Button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>

      {/* <Modal
        open={openEliminare}
        onClose={() => setOpenEliminare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>{desiredMaterial?.nume}</b>
            Sigur elimiati materialul?
            <br />
            <br />
            <span style={{ color: "grey" }}>
              {`
              Eliminandul, materialul va fi sters din ${
                section === "plantatie"
                  ? "plantatia actuala"
                  : "depozitul actual"
              } cat si
              din ${
                section === "plantatie"
                  ? "cantitate plantatie"
                  : "cantitate depozit"
              }  si stocul din depozitul principal.`}
              <br /> Daca doriti sa-l mutati inapoi in depozitul principal fara
              a afecta stocul global, folositi functia de mutare.
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                sx={{
                  bgcolor: "red.dark", // normal background color
                  "&:hover": {
                    bgcolor: "red.light", // hover background color
                  },
                }}
                onClick={handleDeleteMaterial}
              >
                Elimina
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenEliminare(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal> */}
    </>
  );
};

export default NouaLista;
