import React from "react";
import IstoricTabel from "../../../common/Istoric/IstoricTabel";

const istoricVanzari = () => {
  return (
    <IstoricTabel
      url={`https://hammerhead-app-q9545.ondigitalocean.app/api/istoric`}
    />
  );
};

export default istoricVanzari;
