import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Collapse } from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../utils/rapoarte/raportInventarCentralPDF";
import { generateExcelFile } from "./rapoarte/raportXLSX";
import "./listaGestiune.css";
import { useAuth } from "../../common/Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import { useDepozitContext } from "./context/AuthContext";
import InteractiveSVG from "./itemsMap/svgInteractive";
import mapSvg from "../../../images/map1.png";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useNavigate } from "react-router-dom";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { epochFormatDate } from "../../../utils/formats/epochFormat";
import AdaugaMaterialModal from "./modals/AdaugaMaterialModal";
import MutareMaterialModal from "./modals/MutareMaterialModal";
import EditMaterialModal from "./modals/EditeazaMaterialModal";
import DeleteMaterialModal from "./modals/DeleteMaterialModal";

import { useSnackbar } from "notistack";
import DetaliiComanda from "./modals/DetaliiComanda";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const stylePatrat = {
  display: "flex",

  border: "1px solid black",
  flexDirection: "column",
  padding: "10px",
};

const NouaLista = () => {
  const { userData } = useAuth();

  const navigate = useNavigate();

  const [comenzi, setComenzi] = useState([]);

  const [openMaterial, setOpenMaterial] = useState(false);
  const [material, setMaterial] = useState([]);
  const [openMutare, setOpenMutare] = useState(false);
  const [materialId, setMaterialId] = useState();

  const [nume, setNume] = useState();
  const [numeFirma, setNumeFirma] = useState();
  const [model, setModel] = useState();
  const [numarInventar, setNumarInventar] = useState();

  const [categorieProdus, setCategorieProdus] = useState();
  const [dataExpirare, setDataExpirare] = useState();
  const [stoc, setStoc] = useState();

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);
  const [trigger, setTrigger] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const [desiredMaterial, setDesiredMaterial] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const uniqueCategories = [...new Set(comenzi.map((item) => item.modPlata))];
  const uniqueCategoriesDevices = [
    "Laptop",
    "Desktop",
    "Telefon",
    "Imprimanta",
    "Monitor",
  ];

  console.log("uniqc", uniqueCategories);

  const filterItem = (curcat, field) => {
    const newItem = comenzi.filter((newVal) => {
      return newVal[field] === curcat;
    });

    console.log("testing", newItem);
    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handleClose = () => {
    setOpenMaterial(false);
    setOpenMutare(false);
    setNume("");
    setNumeFirma("");
    setModel("");
    setStoc("");
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
    setQuantity("");
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    setSelectedButton(newSelectedButton);
  };

  const handleInputStoc = (e) => {
    const rawValue = e.target.value.replace(/\./g, "");
    setStoc(rawValue);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus();
  };

  const errorHandler = () => {
    return (
      quantity >
      selectedMaterial.stoc -
        selectedMaterial.cantitateDepozit -
        selectedMaterial.cantitateSantier
    );
  };

  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);

    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  //---------------Edit feature-------------

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  async function updateMaterial(e) {
    e.preventDefault();

    const editedItem = {
      nume: formEdit.nume,
      numeFirma: formEdit.numeFirma,
      model: formEdit.model,
      numarInventar: formEdit.numarInventar,
      categorie: newCategory !== "" ? newCategory : formEdit.categorie,
      // dataExpirare: formEdit.dataExpirare,
      stoc: formEdit.stoc,
      author: userData?.name,
    };

    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/${formEdit._id}`,
      {
        credentials: "include",

        method: "PUT",
        body: JSON.stringify(editedItem),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    enqueueSnackbar(`${formEdit.nume} a fost actualizat.`, {
      variant: "success",
    });
    setTrigger(formEdit?.nume);
  }

  const handleCloseEdit = (e) => {
    updateMaterial(e);
    setOpenEdit(false);
  };

  const [openDelete, setOpenDelete] = useState();

  async function deleteMaterial() {
    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/material/${formEdit._id}`,
      {
        credentials: "include",

        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    enqueueSnackbar(`${formEdit.nume} a fost eliminat.`, { variant: "error" });
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenDelete(false);
    setOpenEdit(false);
    setTrigger(formEdit?.nume);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setDesiredMaterial(item); // Set current item here
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const hanldeMutareDesired = (item) => {
    setMaterialId(item);
    setDesiredMaterial(item);
    setOpenMutare(true);
  };

  //----------------- Comenzi------------------

  const plataCheck = (check) => {
    if (check) {
      return (
        <div>
          <CheckIcon color="success" fontSize="large" />
        </div>
      );
    } else {
      return (
        <div>
          <CloseIcon color="error" fontSize="large" />
        </div>
      );
    }
  };

  const url =
    "https://hammerhead-app-q9545.ondigitalocean.app/api/vanzari/comenzi";

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setter(data);
        setFilteredMaterials(data);
      } else if (response.status === 401) {
        // Handle 401 Unauthorized
        alert("Access is forbidden Redirecting to login.");
        navigate("/login"); // Navigate to login page
      } else if (response.status === 403) {
        // Handle 403 Forbidden
        alert("Access is forbidden Redirecting to login.");
        navigate("/login"); // Navigate to login page
      } else {
        alert("Access is forbidden Redirecting to login.");
      }
    } catch (error) {
      alert(`Error fetching data from ${url}: ${error}`);
    }
  };

  useEffect(() => {
    fetchData(url, setComenzi);
  }, [trigger]);

  const [openComanda, setOpenComanda] = useState(false);

  const [formData, setFormData] = useState({
    comandaId: "",
    client: "",
    suma: "",
    author: userData?.nume,
    produse: [],
  });

  const [newSarcina, setNewSarcina] = useState({
    producator: "",
    model: "",
    numarInventar: "",
    categorie: newCategory !== "" ? newCategory : categorieProdus,
    serieSN: "",
    pretA: "",
    pretV: "",
    buc: "",
    tva: "",
    stoc: "",
    author: userData?.name,
  });

  const handleNewSarcinaChange = (field, value) => {
    setNewSarcina((prev) => ({ ...prev, [field]: value }));
  };

  const addNewSarcinaToFormData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      produse: [...prevFormData.produse, newSarcina],
    }));
    // Clear the newSarcina state and close the modal
    setNewSarcina({
      producator: "",
      model: "",
      numarInventar: "",
      categorie: newCategory !== "" ? newCategory : categorieProdus,
      serieSN: "",
      pretA: "",
      pretV: "",
      buc: "",
      tva: "",
      stoc: "",
      author: userData?.name,
    });
    setOpenSarcina(false);

    setNewCat(false);

    console.log("done", formData);
  };

  const [openSarcina, setOpenSarcina] = useState(false);

  const handleNewSarcini = () => {
    setOpenSarcina(true);
  };

  const handleRemoveSarcina = (indexToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      produse: prevFormData.produse.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleInputChange = (key, value, index) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
        sarcini: prevFormData.produse.map((sarcina, i) =>
          i === index ? { ...sarcina, [key]: value } : sarcina
        ),
      };
    });
  };
  const [newCat, setNewCat] = useState(false);

  const createComanda = async () => {
    const totalAmount = formData.produse.reduce((acc, current) => {
      return acc + Number(current.pretV) * Number(current.buc);
    }, 0);
    const startId = `BL-${comenzi.length + 178}`;
    const produsData = {
      comandaId: startId,
      client: formData.client,
      cui: formData.cui,
      modPlata: formData.modPlata,
      platit: formData.platit,
      suma: totalAmount,
      produse: formData.produse,
      author: userData?.name,
    };

    console.log(produsData);

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(produsData),
      });

      if (response.ok) {
        console.log("Produs created successfully");
        enqueueSnackbar(`${startId} a fost creata.`, {
          variant: "success",
        });
        setTrigger(produsData);
        setOpenComanda(false);
      } else {
        console.error("Failed to create comanda");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const [selectedComanda, setSelectedComanda] = useState({});
  const [openComandaDetails, setOpenComandaDetails] = useState(false);

  const handleComandaDetails = (item) => {
    setSelectedComanda(item);
    setOpenComandaDetails(true);
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>Lista comenzi</h3>
            <span>Vanzari: {material.length}</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenComanda(true)}
            >
              Creaza Comanda
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMutare(!openMutare)}
            >
              Muta Materiale
            </Button> */}

            {/* <TextField
              type="text"
              placeholder="Cauta dupa nume..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="desktop-grid-agro">
        <div className="desktop-agro">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
          </div>

          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setFilteredMaterials(comenzi)}
            >
              Toate
            </Button>
          </div>

          {uniqueCategories.map((category) => (
            <div style={{ marginTop: "8px" }}>
              <Button
                key={category}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
                onClick={() => filterItem(category, "modPlata")}
              >
                {category}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <div className="mobile-agro">
            <Button
              variant="contained"
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
            >
              Categorii
            </Button>
            <Collapse in={openCategoryMobile}>
              <div
                style={{
                  borderRight: "1px solid #ccc",
                  padding: "12px",
                  borderRadius: "4px",
                  marginRight: "4px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "grey.dark",
                      color: "white", // normal background color
                      "&:hover": {
                        bgcolor: "grey.light",
                        color: "black", // hover background color
                      },
                      width: "20%",
                    }}
                    onClick={() => handleAllCategory(comenzi)}
                  >
                    Toate
                  </Button>
                </div>

                {uniqueCategories.map((category) => (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      key={category}
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: "grey.dark",
                        color: "white", // normal background color
                        "&:hover": {
                          bgcolor: "grey.light",
                          color: "black", // hover background color
                        },
                        width: "20%",
                      }}
                      onClick={() => filterItem(category, "modPlata")}
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          <div style={{ maxHeight: "62vh", overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    ID
                  </th>
                  <th style={{ paddingRight: "40px" }}>Data</th>
                  <th style={{ paddingRight: "40px" }}>Client</th>
                  <th style={{ paddingRight: "40px" }}>Mod plata</th>
                  <th style={{ paddingRight: "40px" }}>Platit</th>
                  <th style={{ paddingRight: "40px" }}>Suma</th>
                  <th style={{ paddingRight: "40px" }}>Actiuni</th>
                </tr>
              </thead>
              <tbody>
                {filteredMaterials.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        paddingRight: "40px",
                        paddingLeft: "30px",
                        textDecoration: "underline",
                      }}
                      onClick={() => navigate(item._id)}
                    >
                      <span>{item.comandaId}</span>
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {" "}
                      {formatCustomDateString(item.createdAt)}
                    </td>

                    <td style={{ paddingRight: "40px" }}>{item.client}</td>
                    <td style={{ paddingRight: "40px" }}>{item.modPlata}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {plataCheck(item.platit)}
                    </td>
                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.suma)}
                    </td>

                    <td style={{ paddingRight: "40px" }}>
                      <div>
                        <Button
                          id="composition-button"
                          aria-haspopup="true"
                          sx={{ color: "black" }}
                          onClick={(event) => handleClick(event, item)} // Pass item her
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid black",
                              borderRadius: "12px",
                              backgroundColor: "white",
                              marginRight: "14px",
                            }}
                          >
                            <KeyboardArrowDownIcon />
                          </div>
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          PaperProps={{
                            elevation: 0, // Removes the shadow
                            sx: {
                              overflow: "visible",
                              filter: "none", // Removes any filter effects like shadows or blurring
                              // Controls the margin to adjust the position as needed
                              "& .MuiAvatar-root": {
                                width: 42,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              borderRadius: "4px",
                              width: 100,
                              border: "1px solid #D9DCE1",
                            },
                          }}
                        >
                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              handleEditForm(item);
                              handleCloseMenu();
                            }}
                          >
                            Edit
                          </MenuItem>

                          <MenuItem
                            key={index + 1}
                            onClick={() => {
                              hanldeMutareDesired(desiredMaterial);
                              handleCloseMenu();
                            }}
                          >
                            Muta
                          </MenuItem>
                          {/* <MenuItem
                            key={index + 2}
                            onClick={() => {
                              // setOpenEliminare(true);
                              handleCloseMenu();
                            }}
                          >
                            Elimina
                          </MenuItem> */}
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PDFDownloadLink
          document={
            <Factura
              materiale={material}
              section={"inventar"}
              date={currentDate}
            />
          }
          fileName={`${"Raport "}${"inventar"}${" "}${currentDate}${".pdf"}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Generating PDF..."
            ) : (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "blue.focus", // normal background color
                  "&:hover": {
                    bgcolor: "blue.focusHover", // hover background color
                  },
                }}
              >
                {" "}
                Genereaza Raport Inventar
              </Button>
            )
          }
        </PDFDownloadLink>{" "}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "8px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "green.light", // normal background color
            "&:hover": {
              bgcolor: "green.dark", // hover background color
            },
          }}
          onClick={() => generateExcelFile(material, currentDate)}
        >
          Genereaza Raport XLSX
        </Button>
      </div>

      <EditMaterialModal
        {...{
          openEdit,
          setOpenEdit,
          formEdit,
          updateForm,
          uniqueCategories,
          setNewCategory,
          newCategory,
          handleCloseEdit,
          setOpenDelete,
          userData,
          formatCustomDateString, // Assuming this is a function passed as a prop
          styleInv,
        }}
      />

      <DeleteMaterialModal
        {...{
          openDelete,
          setOpenDelete,
          formEdit,
          handleDeleteMaterial,
          styleInv,
        }}
      />

      <Modal
        open={openComanda}
        onClose={() => setOpenComanda(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: "20px",
            }}
          >
            <div>
              <h3>Comanda noua</h3>

              <br />
              <div
                style={{ gap: "4px", display: "flex", flexDirection: "column" }}
              >
                <TextField
                  label="Client"
                  onChange={(e) => handleInputChange("client", e.target.value)}
                />
                <TextField
                  label="CUI"
                  onChange={(e) => handleInputChange("cui", e.target.value)}
                />
                <InputLabel id="mod-plata-label">Mod plata</InputLabel>
                <Select
                  labelId="mod-plata-label"
                  value={formData.modPlata}
                  label="Mod plata"
                  onChange={(e) =>
                    handleInputChange("modPlata", e.target.value)
                  }
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Factura">Factura</MenuItem>
                  <MenuItem value="Chitanta">Chitanta</MenuItem>
                  <MenuItem value="Altele">Altele</MenuItem>
                </Select>
                <InputLabel id="mod-plata-label">Platit</InputLabel>
                <Select
                  labelId="mod-plata-label"
                  value={formData.platit}
                  label="Platit"
                  onChange={(e) => handleInputChange("platit", e.target.value)}
                >
                  <MenuItem value={true}>Da</MenuItem>
                  <MenuItem value={false}>Nu</MenuItem>
                </Select>
                <hr />
                Total:{" "}
                {formData.produse.reduce((acc, current) => {
                  return acc + Number(current.pretV) * Number(current.buc);
                }, 0)}{" "}
                Ron (TVA inclus)
                <br />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <InputLabel id="mod-plata-label">Lista Produse</InputLabel>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                        Producator
                      </th>
                      <th style={{ paddingRight: "40px" }}>Model</th>

                      <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
                      <th style={{ paddingRight: "40px" }}>Buc</th>

                      <th style={{ paddingRight: "40px" }}>TVA</th>

                      <th style={{ paddingRight: "40px" }}>Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.produse?.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{ paddingRight: "40px", paddingLeft: "30px" }}
                        >
                          <Tooltip
                            title={
                              <Typography
                                variant="body2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                adaugat de {item.author} la{" "}
                                {formatCustomDateString(item.createdAt)}
                              </Typography>
                            }
                            placement="right"
                          >
                            <span>{item.producator}</span>
                          </Tooltip>
                        </td>
                        <td style={{ paddingRight: "40px" }}>{item.model}</td>

                        <td style={{ paddingRight: "40px" }}>
                          {formatNumberWithDots(item.pretV)}
                        </td>
                        <td style={{ paddingRight: "40px" }}>
                          {formatNumberWithDots(item.buc)}
                        </td>
                        <td style={{ paddingRight: "40px" }}>{item.tva}</td>
                        <td style={{ paddingRight: "40px" }}>
                          <Button
                            variant="contained"
                            onClick={() => handleRemoveSarcina(index)}
                          >
                            Sterge
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                <Button
                  variant="contained"
                  sx={{ bgcolor: "green.light" }}
                  onClick={() => setOpenSarcina(true)}
                >
                  Adauga Produse
                </Button>{" "}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  sx={{ bgcolor: "green.light" }}
                  onClick={createComanda}
                >
                  Adauga Comanda
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal open={openSarcina} onClose={() => setOpenSarcina(false)}>
        <Box sx={styleInv}>
          <div>
            <h3>Adauga produs</h3>
            <br />
            <div
              style={{ gap: "4px", display: "flex", flexDirection: "column" }}
            >
              <TextField
                label="Producator"
                onChange={(e) =>
                  handleNewSarcinaChange("producator", e.target.value)
                }
              />
              <TextField
                label="Model"
                onChange={(e) =>
                  handleNewSarcinaChange("model", e.target.value)
                }
              />

              <InputLabel
                id="demo-simple-select-label"
                style={{ paddingTop: 4 }}
              >
                Categorie{" "}
              </InputLabel>

              <Select
                style={{ paddingBottom: 4 }}
                label="Categorie"
                value={formData.categorie}
                onChange={(e) => {
                  console.log("Selected value:", e.target.value); // Debug log
                  if (e.target.value === "adauga") {
                    updateForm("categorie", e.target.value);
                    setNewCat(true);
                    handleNewSarcinaChange("categorie", e.target.value);
                  } else {
                    setNewCat(false);

                    handleNewSarcinaChange("categorie", e.target.value);
                    setNewCategory("");
                  }
                }}
              >
                {uniqueCategoriesDevices.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                <MenuItem value="adauga">Adauga Categorie Noua</MenuItem>
              </Select>

              {newCat && (
                <TextField
                  type="text"
                  placeholder="Categorie Noua"
                  value={newCategory}
                  onChange={(e) => {
                    setNewCategory(e.target.value); // Debug log
                    handleNewSarcinaChange("categorie", e.target.value);
                  }}
                />
              )}
              <TextField
                label="Numar Inventar"
                onChange={(e) =>
                  handleNewSarcinaChange("numarInventar", e.target.value)
                }
              />

              <TextField
                label="Serie S/N"
                onChange={(e) =>
                  handleNewSarcinaChange("serieSN", e.target.value)
                }
              />
              <TextField
                label="Bucati"
                onChange={(e) => handleNewSarcinaChange("buc", e.target.value)}
              />
              <TextField
                label="Pret Achizitie"
                onChange={(e) =>
                  handleNewSarcinaChange("pretA", e.target.value)
                }
              />
              <TextField
                label="Pret Vanzare"
                onChange={(e) =>
                  handleNewSarcinaChange("pretV", e.target.value)
                }
              />

              <TextField
                label="TVA"
                onChange={(e) => handleNewSarcinaChange("tva", e.target.value)}
              />
            </div>
            <br />
            <Button
              variant="contained"
              sx={{ bgcolor: "green.light" }}
              onClick={addNewSarcinaToFormData}
            >
              Adauga Produs
            </Button>{" "}
          </div>
        </Box>
      </Modal>

      <DetaliiComanda
        comanda={selectedComanda}
        open={openComandaDetails}
        handleClose={() => setOpenComandaDetails(false)}
      />
    </>
  );
};

export default NouaLista;
