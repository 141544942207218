import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import md5 from "md5";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import sma from "../../../images/smaTitle.png";
import "./LoginPage.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

const headerStyle = {
  fontSize: "40px",
  fontWeight: 500,
};

const textFieldStyle = {
  marginBottom: "16px",
  width: "100%",
};

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

const Login = () => {
  const [username, setUsername] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");
  const [verified, setVerified] = useState(true);
  const [error, setError] = useState("");
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();

  const [role, setRole] = React.useState("standard");

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const currentDate = new Date();

  const sevenDaysLater = new Date(currentDate);
  const expireDate = sevenDaysLater.setDate(currentDate.getDate() + 7);

  const futureDate = new Date(currentDate.getTime() + 10 * 1000);

  const handleEmailChange = (event) => {
    const trimmedEmail = event.target.value.trim();
    setEmail(trimmedEmail);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfPassword = (event) => {
    setConfPass(event.target.value);
    if (password === confPass) {
      setVerified(false);
    } else {
      setVerified(true);
    }
  };

  const handleRoleText = () => {
    switch (role) {
      case "standard":
        return "Only read rights.";
      case "moderator":
        return "Read and write rights.";
      case "admin":
        return "Full rights. (read, write, delete, add)";
      // Add more cases if needed
      default:
        return ""; // Default case, return an empty string or another default value
    }
  };

  // const handleLoginClick = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const encryptedPassword = md5(password);

  //     const response = await fetch("https://hammerhead-app-q9545.ondigitalocean.app/api/users/auth", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //       body: JSON.stringify({
  //         name: username,
  //         email,
  //         password: encryptedPassword,
  //       }),
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       throw new Error(error.message);
  //     }

  //     const loginData = await response.json();

  //     localStorage.setItem("expireDate", expireDate);
  //     localStorage.setItem("login", true);

  //     setUserData(loginData);
  //     setIsAuthenticated(true);
  //     navigate("/");
  //   } catch (error) {
  //     setError(error.message);
  //     console.log(error);
  //   }
  // };

  const handleSignUpClick = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      alert("Invalid email address");
      return;
    } else if (!password.trim()) {
      {
        alert("Please enter a password");
        return;
      }
    } else {
      try {
        const encryptedPassword = md5(password);

        const response = await fetch(
          "https://hammerhead-app-q9545.ondigitalocean.app/api/users",
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: username,
              email,
              password: encryptedPassword,
              role,
              isAdmin: role === "admin",
            }),
          }
        );

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message);
        }
        navigate("/login");
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: "3%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="login-screen">
        <Typography style={headerStyle}>Welcome to</Typography>
        <img src={sma} width="300px" alt="logo" />
        <br />
        <br />

        <Typography>
          We are excited to bring more people on board!
          <br />
          Due to security reasons, only admin users are able to sign up new
          users.
        </Typography>
        <br />
        {error && <div className="error">{error}</div>}

        <form onSubmit={handleSignUpClick}>
          <TextField
            id="outlined-basic"
            label="Name"
            xs
            variant="outlined"
            type="text"
            style={textFieldStyle}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            style={textFieldStyle}
            value={email}
            onChange={handleEmailChange}
            required
          />
          <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            onChange={handleChange}
          >
            <MenuItem value={"standard"}>Standard</MenuItem>
            <MenuItem value={"moderator"}>Moderator</MenuItem>
            <MenuItem value={"admin"}>Admin</MenuItem>
          </Select>
          <FormHelperText>{handleRoleText()}</FormHelperText>
          <br />

          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            style={textFieldStyle}
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <TextField
            id="outlined-basic"
            label="Confirm password"
            variant="outlined"
            type="password"
            style={textFieldStyle}
            value={confPass}
            onChange={handleConfPassword}
            required
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleSignUpClick}
            >
              Sign up
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
