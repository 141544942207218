import React, { useState, useEffect } from "react";
import "./SideMenu.css";
import { Link } from "react-router-dom";
import {
  ListItem,
  Collapse,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../Login/AuthContext";
import { useDepozitContext } from "../../gestiune/gestiuneEco/context/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import packageJson from "../../../../package.json";

import TruckIcon from "../../../images/transNova.png";
import AgroIcon from "../../../images/agroNova.png";
import GestIcon from "../../../images/gestNova.png";
import ClientIcon from "../../../images/clientiNova.png";
import SalesIcon from "../../../images/salesIcon2.png";
import HRIcon from "../../../images/hr.png";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styledSideMenu = {
  fontWeight: 300,
  fontSize: "16px",
  marginRight: "8px",
};

const styledSection = {
  fontWeight: 400,
  fontSize: "16px",
  color: "#D9DCE1",
};

const SideMenu = ({ setDarkMode, darkMode }) => {
  const material2 = {
    nume: "Alege Depozit",
  };
  const version = packageJson.version;

  const { isAuthenticated, userData } = useAuth();
  const { isNewDepozit, setIsNewDepozit } = useDepozitContext();

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);

  const [santiersAgro, setSantiersAgro] = useState([]);
  const [depozitsAgro, setDepozitsAgro] = useState([]);

  const [nume, setNume] = useState();
  const [locatie, setLocatie] = useState();
  const [selectedDepozit, setSelectedDepozit] = useState(material2);

  const [open2, setOpen2] = useState(false);

  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [selectedSubsectionIndex, setSelectedSubsectionIndex] = useState(null);
  const [trigger, setTrigger] = useState();

  const sectiuniArray = [
    {
      nume: "Agronomie",
      icon: AgroIcon,
      sectiuni: [
        {
          nume: "Depozite",
          data: depozitsAgro,
          dropdown: true,
          nick: "Depozit",
        },
        {
          nume: "Plantatii",
          data: santiersAgro,
          dropdown: true,
          nick: "Plantatie",
        },
        {
          nume: "Depozit",
          link: "agronomie/materiale",
        },
        {
          nume: "Statistici",
          link: "agronomie/statistici",
        },
      ],
    },
    {
      nume: "Vanzari",
      icon: SalesIcon,
      sectiuni: [
        {
          nume: "Clienti",
          link: "vanzari/clienti",
        },
        {
          nume: "Lista vanzari",
          link: "vanzari/lista",
        },
        {
          nume: "Comenzi",
          link: "vanzari/comenzi",
        },
        {
          nume: "Inventar",
          link: "vanzari/inventar",
        },
        {
          nume: "Istoric",
          link: "vanzari/istoric",
        },
      ],
    },
    {
      nume: "HR",
      icon: HRIcon,
      sectiuni: [
        {
          nume: "Angajati",
          link: "hr/angajati",
        },
        {
          nume: "Recrutare",
          link: "hr/recrutare",
        },
      ],
    },
  ];

  // const toggleTheme = () => {
  //   setDarkMode(!darkMode);
  // };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleModalAdauga = () => {
    setOpen2(false);
    setSelectedDepozit("");
  };

  const toggleSection = (index) => {
    setOpenSectionIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedSubsectionIndex(null);
  };

  const toggleSubsection = (index) => {
    setSelectedSubsectionIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const createDepozit = async () => {
    let depozitData = {
      nume: nume,
      locatie: locatie,
      depozitTata: [selectedDepozit],
      author: userData.name, // trebe sa mai adaug user data
    };
    if (
      sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
        ?.nick === "Depozit"
    ) {
      depozitData = {
        nume: nume,
        locatie: locatie,
        author: userData.name, // trebe sa mai adaug user data
      };
    }
    const apiUrl =
      sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
        ?.nume === "Depozite"
        ? "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/depozit"
        : "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/santier";

    try {
      const response = await fetch(apiUrl, {
        credentials: "include",

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(depozitData),
      });
      if (response.ok) {
        setOpen2(!open2);
        setIsNewDepozit(depozitData);
      } else {
        console.error("Failed to create depozit");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const createPlantatie = async () => {
    let depozitData = {
      nume: nume,
      locatie: locatie,
      depozitTata: [selectedDepozit],
      author: userData.name, // trebe sa mai adaug user data
    };
    if (
      sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
        ?.nick === "Depozit"
    ) {
      depozitData = {
        nume: nume,
        locatie: locatie,
        author: userData.name, // trebe sa mai adaug user data
      };
    }
    const apiUrl =
      sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
        ?.nick === "Depozit"
        ? "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/depozit"
        : "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/santier";

    try {
      const response = await fetch(apiUrl, {
        credentials: "include",

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(depozitData),
      });
      setTrigger(depozitData);
      if (response.ok) {
        setOpen2(!open2);
        setIsNewDepozit(depozitData);
      } else {
        console.error("Failed to create plantatie");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    async function fetchSantiersAgro() {
      try {
        const response = await axios.get(
          "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/santier",
          { withCredentials: true }
        );
        setSantiersAgro(response.data);
      } catch (error) {
        console.error("Error fetching santiers:", error);
      }
    }

    async function fetchDepozitsAgro() {
      try {
        const response = await axios.get(
          "https://hammerhead-app-q9545.ondigitalocean.app/api/agronomie/depozit",
          { withCredentials: true }
        );

        setDepozitsAgro(response.data);
      } catch (error) {
        console.error("Error fetching santiers:", error);
      }
    }

    fetchDepozitsAgro();
    fetchSantiersAgro();
  }, [isNewDepozit, trigger]);

  return (
    <>
      <div className="side-menu-container">
        <div className="side-menu">
          <div>
            {isAuthenticated &&
              sectiuniArray?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <ListItem
                    button
                    onClick={() => toggleSection(sectionIndex, section)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 4,
                        paddingTop: "16px",
                      }}
                    >
                      <img src={section.icon} alt="icon" width="20px" />

                      <span style={styledSection}>{section?.nume}</span>
                    </div>
                  </ListItem>
                  <Collapse in={openSectionIndex === sectionIndex}>
                    {section?.sectiuni?.map((subsection, subsectionIndex) => (
                      <div key={subsectionIndex} className="right-menu-item">
                        {subsection?.dropdown === true ? (
                          <ListItem
                            button
                            onClick={() => toggleSubsection(subsectionIndex)}
                          >
                            <span style={styledSideMenu}>
                              {subsection?.nume}
                            </span>
                            <svg
                              className="svgIconList"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M20 7L4 7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                ></path>{" "}
                                <path
                                  d="M15 12L4 12"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                ></path>{" "}
                                <path
                                  d="M9 17H4"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                ></path>{" "}
                              </g>
                            </svg>
                          </ListItem>
                        ) : (
                          <Link
                            className="nav-link"
                            to={
                              subsection?.dropdown === true
                                ? `https://ecofrux.smarty-erp.ro/agronomie/${subsection?.link}`
                                : `https://ecofrux.smarty-erp.ro/${subsection?.link}`
                            }
                            onClick={() => setSelectedSubsectionIndex(null)}
                          >
                            {subsection?.nume}
                          </Link>
                        )}
                      </div>
                    ))}
                  </Collapse>
                </div>
              ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: " center",
              paddingBottom: "8px",
              flexDirection: "column",
            }}
          >
            {" "}
            {/* <span>mode: {darkMode ? "darkMode" : "lightMode"}</span>
            <Button onClick={toggleTheme}>Theme</Button> */}
            <span style={{ color: "#D9DCE1" }}>{`v${version}`}</span>
          </div>
        </div>
        {/* Right-side menu */}
        {selectedSubsectionIndex !== null && (
          <div className="right-side-menu" key={isNewDepozit}>
            <div className="right-side-menu-box">
              {sectiuniArray[openSectionIndex]?.sectiuni[
                selectedSubsectionIndex
              ]?.data?.map((item, itemIndex) => (
                <Link
                  className="nav-link-right"
                  to={
                    openSectionIndex === 1
                      ? `https://ecofrux.smarty-erp.ro/gestiune/${sectiuniArray[
                          openSectionIndex
                        ]?.sectiuni[
                          selectedSubsectionIndex
                        ]?.nick.toLowerCase()}/${item._id}`
                      : `https://ecofrux.smarty-erp.ro/agronomie/${sectiuniArray[
                          openSectionIndex
                        ]?.sectiuni[
                          selectedSubsectionIndex
                        ]?.nick.toLowerCase()}/${item._id}`
                  }
                  key={itemIndex}
                >
                  {" "}
                  <span>{item.nume} </span>
                </Link>
              ))}
            </div>
            <br />
            <Button
              variant="contained"
              onClick={() => setOpen2(!open2)}
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
            >
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </Button>
          </div>
        )}
      </div>

      <Modal
        open={open2}
        onClose={handleModalAdauga}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </h2>
            {depozitsAgro.length === 0 &&
              sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
                ?.nick !== "Depozit" && (
                <span style={{ color: "red" }}>
                  {" "}
                  Intai trebuie creat un depozit de care sa apartina.
                </span>
              )}
            <br />
            <TextField
              type="text"
              placeholder={`Nume ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder={`Locatie ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setLocatie(e.target.value)}
            ></TextField>
            <br />
            <br />

            {sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
              ?.nick !== "Depozit" && (
              <>
                <InputLabel id="demo-simple-select-label">
                  Depozit{" "}
                  <Tooltip
                    title={`Depozitul de care apartine.`}
                    placement="right"
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputLabel>
                <Select
                  value={selectedDepozit}
                  onChange={(e) => handleDepozitChange(e)}
                  style={{ width: "30%" }}
                >
                  {depozitsAgro.map((material) => (
                    <MenuItem key={material._id} value={material._id}>
                      {material.nume} - {material.locatie}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <br />
              </>
            )}

            <Button
              onClick={
                openSectionIndex === 2
                  ? () => createPlantatie()
                  : () => createDepozit()
              }
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              disabled={
                depozitsAgro.length === 0 &&
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick !== "Depozit"
              }
            >
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SideMenu;
