import React, { useState } from "react";
import { Button, Collapse, Menu, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Select, MenuItem, InputLabel, Modal, Box } from "@mui/material";
import { useAuth } from "../../../common/Login/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const ClientiView = ({ clienti, setTrigger }) => {
  const { userData } = useAuth();
  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  const [filteredMaterials, setFilteredMaterials] = useState(clienti);

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const plataCheck = (check) => {
    if (check) {
      return (
        <div>
          <CheckIcon color="success" fontSize="large" />
        </div>
      );
    } else {
      return (
        <div>
          <CloseIcon color="error" fontSize="large" />
        </div>
      );
    }
  };

  console.log("filter", filteredMaterials);

  const uniqueCategories = [
    { nume: "Platite", val: true },
    { nume: "Neplatite", val: false },
  ];

  const filterItem = (curcat) => {
    const newItem = clienti.filter((newVal) => {
      return newVal.platit === curcat;
    });

    console.log(curcat);
    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handlePlata = async (item) => {
    const data = {
      client: item.client,
      cui: item.cui,
      author: userData?.name,
      platit: true,
      plataReq: true,
    };

    console.log("all", { av: item, sent: data });

    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/vanzari/clienti/${item._id}`,
      {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setTrigger(item);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentItem, setCurrentItem] = useState(null); // Add this line

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrentItem(item); // Set current item here
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEditClient, setOpenEditClient] = useState(false);

  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setOpenEditClient(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  async function updateMaterial(e) {
    e.preventDefault();

    const data = {
      client: formEdit.client,
      cui: formEdit.cui,
      modPlata: formEdit.modPlata,
      platit: formEdit.platit,
      suma: formEdit.suma,
      author: userData?.name,
    };

    await fetch(
      `https://hammerhead-app-q9545.ondigitalocean.app/api/vanzari/clienti/${formEdit._id}`,
      {
        credentials: "include",

        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  const handleCloseEdit = (e) => {
    updateMaterial(e);
    setOpenEditClient(false);
  };

  return (
    <div className="desktop-grid-agro">
      <div className="desktop-agro">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
        </div>

        <div style={{ marginTop: "8px" }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setFilteredMaterials(clienti)}
            sx={{
              bgcolor: "grey.dark",
              color: "white", // normal background color
              "&:hover": {
                bgcolor: "grey.light",
                color: "black", // hover background color
              },
            }}
          >
            Toate
          </Button>
        </div>

        {uniqueCategories.map((category) => (
          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => filterItem(category.val)}
            >
              {category.nume}
            </Button>
          </div>
        ))}
      </div>
      <div className="mobile-agro">
        <Button
          variant="contained"
          onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
          sx={{
            bgcolor: "grey.dark",
            color: "white", // normal background color
            "&:hover": {
              bgcolor: "grey.light",
              color: "black", // hover background color
            },
          }}
        >
          Categorii
        </Button>
        <Collapse in={openCategoryMobile}>
          <div
            style={{
              borderRight: "1px solid #ccc",
              padding: "12px",
              borderRadius: "4px",
              marginRight: "4px",
            }}
          >
            <div style={{ marginTop: "8px" }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleAllCategory(clienti)}
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
              >
                Toate
              </Button>
            </div>

            {uniqueCategories.map((category) => (
              <div style={{ marginTop: "8px" }}>
                <Button
                  key={category.val}
                  variant="contained"
                  fullWidth
                  onClick={() => filterItem(category.val)}
                  sx={{
                    bgcolor: "grey.dark",
                    color: "white", // normal background color
                    "&:hover": {
                      bgcolor: "grey.light",
                      color: "black", // hover background color
                    },
                  }}
                >
                  {category.nume}
                </Button>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
              Client
            </th>
            <th style={{ paddingRight: "40px" }}>CUI</th>
            <th style={{ paddingRight: "40px" }}>Comanda ID</th>
            <th style={{ paddingRight: "40px" }}>Mod plata</th>
            <th style={{ paddingRight: "40px" }}>Platit</th>
            <th style={{ paddingRight: "40px" }}>Suma</th>
            <th style={{ paddingRight: "40px" }}>Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {filteredMaterials.map((item, index) => (
            <tr key={index}>
              <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                {item.client}
              </td>
              <td style={{ paddingRight: "40px" }}>{item.cui}</td>
              <td style={{ paddingRight: "40px" }}>{item.comandaId}</td>
              <td style={{ paddingRight: "40px" }}>{item.modPlata}</td>
              <td style={{ paddingRight: "40px" }}>
                {plataCheck(item.platit)}
              </td>
              <td style={{ paddingRight: "40px" }}>
                {formatNumberWithDots(item.suma)}
              </td>
              <td style={{ paddingRight: "40px" }}>
                <div>
                  <Button
                    id="composition-button"
                    aria-haspopup="true"
                    sx={{ color: "black" }}
                    onClick={(event) => {
                      handleClick(event, item);
                      setFormEdit(item);
                    }} // Pass item her
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid black",
                        borderRadius: "12px",
                        backgroundColor: "white",
                        marginRight: "14px",
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </div>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0, // Removes the shadow
                      sx: {
                        overflow: "visible",
                        filter: "none", // Removes any filter effects like shadows or blurring
                        // Controls the margin to adjust the position as needed
                        "& .MuiAvatar-root": {
                          width: 42,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "4px",
                        width: 100,
                        border: "1px solid #D9DCE1",
                      },
                    }}
                  >
                    <MenuItem
                      key={index + 1}
                      onClick={() => {
                        handlePlata(currentItem);
                        handleClose();
                      }}
                    >
                      Plata
                    </MenuItem>
                    <MenuItem
                      key={index + 2}
                      onClick={() => {
                        handleEditForm(true);
                        handleClose();
                      }}
                    >
                      Edit
                    </MenuItem>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={openEditClient}
        onClose={() => setOpenEditClient(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Editare client</h3>
          <div style={{ gap: "8px", display: "flex", flexDirection: "column" }}>
            <InputLabel id="demo-simple-select-label">Client</InputLabel>
            <TextField
              value={formEdit?.client}
              onChange={(e) => updateForm({ client: e.target.value })}
            />
            <InputLabel id="demo-simple-select-label">CUI</InputLabel>
            <TextField
              value={formEdit?.cui}
              onChange={(e) => updateForm({ cui: e.target.value })}
            />
            <InputLabel id="demo-simple-select-label">Mod plata</InputLabel>
            <Select
              labelId="mod-plata-label"
              value={formEdit?.modPlata}
              label="Platit"
              onChange={(e) => updateForm({ modPlata: e.target.value })}
            >
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Factura">Factura</MenuItem>
              <MenuItem value="Chitanta">Chitanta</MenuItem>
              <MenuItem value="Altele">Altele</MenuItem>
            </Select>
            <InputLabel id="demo-simple-select-label">Platit</InputLabel>
            <Select
              labelId="mod-plata-label"
              value={formEdit?.platit}
              label="Platit"
              onChange={(e) => updateForm({ platit: e.target.value })}
            >
              <MenuItem value={true}>Da</MenuItem>
              <MenuItem value={false}>Nu</MenuItem>
            </Select>
            <InputLabel id="demo-simple-select-label">Suma</InputLabel>
            <TextField
              value={formEdit?.suma}
              onChange={(e) => updateForm({ suma: e.target.value })}
            />
          </div>
          <br />
          <Button
            variant="contained"
            sx={{ bgcolor: "green.light" }}
            onClick={handleCloseEdit}
          >
            Actualizeaza
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ClientiView;
